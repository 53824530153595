import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  mgbInfinity = ["assets/mgbInfinity/1.jpeg","assets/mgbInfinity/2.jpeg","assets/mgbInfinity/3.jpeg"];
  rvVibhunam = ["assets/rvVibhunam/1.jpeg","assets/rvVibhunam/2.jpeg"];
  sreeSumeru = ["assets/sreeSumeru/1.jpeg","assets/sreeSumeru/2.jpeg"];
  sukhiTatva = ["assets/sukhiTatva/1.jpeg","assets/sukhiTatva/2.jpeg"];
  sukhi = ["assets/sukhi/2.jpeg","assets/sukhi/3.jpeg","assets/sukhi/4.jpeg","assets/sukhi/5.jpeg"];
  usha = ["assets/usharam/5.png","assets/usharam/6.png","assets/usharam/7.jpeg","assets/usharam/2.png"];
  gp = ["assets/gp/1.jpg","assets/gp/3.png","assets/gp/5.jpeg","assets/gp/6.jpeg"];
  honor = ["assets/honor/1.jpg","assets/honor/2.jpeg"];
  ambi = ["assets/ambi/1.jpg","assets/ambi/2.jpg","assets/ambi/3.jpg","assets/ambi/4.jpg","assets/ambi/5.jpg","assets/ambi/6.jpg"];
  vasavi = ["assets/vasavi/1.jpg","assets/vasavi/2.jpg","assets/vasavi/3.jpg","assets/vasavi/4.jpg","assets/vasavi/5.jpg","assets/vasavi/6.jpg"];
  brindavan = ["assets/brindavan/1.jpg","assets/brindavan/5.jpeg"];
  lakmi = ["assets/lakmi/1.png","assets/lakmi/2.jpg","assets/lakmi/3.jpg"];
  nilayam = ["assets/Nilayam/4.png","assets/Nilayam/6.jpeg","assets/Nilayam/2.png","assets/Nilayam/5.png","assets/Nilayam/1.png"];
 clientsImg=["assets/clientsLogo/1.jpeg","assets/clientsLogo/2.jpeg","assets/clientsLogo/3.jpeg","assets/clientsLogo/4.jpeg"]
 clientsImg1=["assets/clientsLogo/5.jpeg","assets/clientsLogo/6.jpeg","assets/clientsLogo/7.jpeg","assets/clientsLogo/8.jpeg"];


  

  machines=[{
    "name":"Total Station",
    "capacity":"6 NOS"

  },{
    "name":"Tower Cranes",
    "capacity":"6 NOS"

  },{
    "name":"MS plates Centering",
    "capacity":"An area of 5 lakh sft"

  },{
    "name":"Bar Bending Machines",
    "capacity":"30 NOS"

  },{
    "name":"Bar Cutting Machines",
    "capacity":"25 NOS"

  },{
    "name":"Cuplock",
    "capacity":"An area of 4 lakh sft."

  },{
    "name":"MS channel",
    "capacity":"600MT"

  }]
  constructor() { 
 
  }
  
  ngOnInit() {
    

 
  }

}
