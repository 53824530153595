import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    var images = [
      "assets/sukhi1.jpeg",
      "assets/head1.jpeg",
      "assets/carousal3.jpeg",
      "assets/Nilayam/6.jpeg",
      "assets/helipad.jpeg",
      "assets/carousal4.jpeg"
    ]
    
    var imageHead = document.getElementById("top");
    var i = 0;
    
    setInterval(function() {
          imageHead.style.backgroundImage = "url("+images[i]+")";//"linear-gradient(to bottom, rgba(22, 22, 22, 0.3) 0%, rgba(22, 22, 22, 0.7) 75%, #161616 100%), url('../../'"+images[i]+"')' !important";
          i = i + 1;
          if (i == images.length) {
            i =  0;
          }
    }, 5000);
  }



}
